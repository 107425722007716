import React, { useEffect, useRef, useState } from 'react';
import { formatRupiah, sendAnalyticsEvent } from '../../utils/helper';
import { StarIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid';
import FoodImg from '../../common/images/food.png';
import ImageLoader from '../ImageLoader';

const ProductName = ({ name, longestName }) => {
  const nameRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState(0);
  const [isTwoLines, setIsTwoLines] = useState(false);
  const canvasRef = useRef(document.createElement("canvas"));

  useEffect(() => {
    if (!nameRef?.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setMaxWidth(nameRef?.current?.offsetWidth);
    });

    resizeObserver?.observe(nameRef?.current);

    return () => resizeObserver?.disconnect();
  }, []);

  useEffect(() => {
    if (!maxWidth) return;
    const context = canvasRef?.current.getContext("2d");
    const textWidth = context?.measureText(longestName || name).width;

    setIsTwoLines(textWidth > maxWidth);
  }, [name, longestName, maxWidth]);

  return (
    <div ref={nameRef} className="flex flex-col flex-1">
      <div className={`text-gray-800 text-xs font-semibold mt-1 ${isTwoLines ? "line-clamp-2 min-h-[2rem]" : ""}`}>
        {name}
      </div>
    </div>
  );
};

const ProductDetail = (props) => {
	const {
		id = 0,
		slug = '',
		image = FoodImg,
		rating = 0,
		totalRating = 0,
		name = '',
		price = 0,
		strikeOutPrice = 0,
		size = 'big',
		handleGetProductDetail = (slug) => {},
		productType = '1',
		cart = null,
		handleCart = (data) => {},
    groupedColumns = [],
    idx = 0
	} = props;
	const currentCartItem = cart?.item?.find((item) => item.productID === id) || null;
	const [totalComboProduct, setTotalComboProduct] = useState(0);

	const handleClick = async (productType, type, id, slug) => {
		if (productType === '2' && Number(currentCartItem?.itemQuantity) > 0) {
			return handleGetProductDetail(slug);
		}
		// 1 = simple product
		// 2 = combo product
		let prevTotal = Number(currentCartItem?.itemQuantity);
		if (type === 'plus') {
			prevTotal = prevTotal + 1;
		} else {
			prevTotal = prevTotal !== 0 ? prevTotal - 1 : 0;
		}

		const dataCart = {
			productID: id,
			quantity: prevTotal,
			type: !cart ? 'add' : 'update',
			productType: productType === '1' ? 'Simple' : 'Combo',
			itemID: currentCartItem?.itemID || null,
		};

		handleCart(dataCart);
	};

	const findTotalProductQuantity = () => {
		const currentCartItems = cart?.item?.filter((item) => item.productID === id && id) || [];
		const totalProductQuantity = currentCartItems?.reduce((total, item) => {
			return total + parseInt(item.itemQuantity, 10);
		}, 0);
    setTotalComboProduct(totalProductQuantity);
	};

	useEffect(() => {
		if (productType === '1' || !currentCartItem) return;
		findTotalProductQuantity();
	}, [currentCartItem, productType]);


  const rows = (idx) => {
    const foundRow = groupedColumns?.find(group => group.group.includes(idx));
    return foundRow ? foundRow : {};
  };
  
  const row = rows(idx);

	return (
			<div 
        key={id} 
        className={`${size === 'big' ? 'h-full w-full ' : 'flex flex-col min-w-44'} !z-0`}
        onClick={() => {
          sendAnalyticsEvent("Click Product", 'merchant_add_product', name)
          handleGetProductDetail(slug)
        }}
        id='merchant_add_product'>
				<div className={`aspect-[1/1] relative ${size === 'big' ? 'w-full h-auto' : 'max-h-36 max-w-36'}`}>
					<ImageLoader alt={slug} src={image} />
					<div className='absolute bottom-0 right-0 m-2 z-10'>
						{Number(currentCartItem?.itemQuantity || 0) ? (
							<div className='bg-white p-1 rounded-md cursor-pointer flex items-center border-solid border-1 border-byzantium'>
								<MinusIcon 
                  className='h-5 w-5 text-byzantium' 
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(productType, 'minus', id, slug)}
                  }
                />
								<input
									type='text'
									className='!bg-white !m-0 !p-0 !h-5 !w-8 text-center text-black font-semibold text-xs'
									value={Number(totalComboProduct || currentCartItem?.itemQuantity || 0)}
									disabled
								/>
								<PlusIcon 
                  className='h-5 w-5 text-byzantium' 
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(productType, 'plus', id, slug)}
                  }
                />
							</div>
						) : (
							<div className='bg-white p-1 rounded-md cursor-pointer border-1 border-solid border-byzantium'>
								<PlusIcon className='h-5 w-5 text-byzantium' />
							</div>
						)}
					</div>
				</div>
				<div className='flex gap-1 items-center pt-2'>
					<StarIcon className='h-4 w-4 text-star' />
					<div className='text-black text-xs'>{rating}</div>
					<div className='text-gray-900 text-xs'>{totalRating && `(${totalRating})`}</div>
				</div>
        <ProductName name={name} longestName={row?.longest_name} />
				<div className='text-xs flex items-center pt-1'>
					<div className='font-semibold pr-1 text-gray-800'>{formatRupiah(price)}</div>
					{Boolean(strikeOutPrice) && <div className='line-through text-gray-500'>{formatRupiah(strikeOutPrice)}</div>}
				</div>
			</div>
	);
};

export default ProductDetail;
