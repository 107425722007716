import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { deliveryId, uniqueID } from "../../utils/constants";
import {
  axiosInstance,
  encodeValue,
  formatRupiah,
  sendAnalyticsCustomEvent,
  sendAnalyticsEvent,
  sendAnalyticsPageView,
} from "../../utils/helper";
import Loader from "../../components/Loader/index";
import {
  MagnifyingGlassIcon,
  ArrowLeftIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import { HeartIcon } from "@heroicons/react/24/outline";
import {
  BikeIcon,
  DiscountIcon,
  LoaderIcon,
  TagIcon,
  WishtlingIcon,
} from "../../utils/svg";
import Footer from "../../components/Footer";
import qs from "qs";
import ProductDetail from "../../components/Product";
import ProductSimple from "../../components/Product/simple";
import { BottomSheet } from "react-spring-bottom-sheet";
import ProductCombo from "../../components/Product/combo";
import { useDispatch, useSelector } from "react-redux";
import useToast from "../../components/Toast";
import ImageLoader from "../../components/ImageLoader";
import NoOutlet from "../../components/Outlet/NoOutlet";

const Outlet = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { state } = location || {};
  const { slug } = useParams();

  const { showToast } = useToast();

  const user = useSelector((store) => store?.user);

  const [isLoading, setIsLoading] = useState(false);
  const [outlet, setOutlet] = useState(null);

  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [tags, setTags] = useState([]);
  const [cart, setCart] = useState(null);

  const [isShowTotalCart, setIsShowTotalCart] = useState(false);
  const [isShowSimpleProduct, setIsShowSimpleProduct] = useState(false);
  const [isShowComboProduct, setIsShowComboProduct] = useState(false);

  const [productSlug, setProductSlug] = useState(null);
  const [cartItem, setCartItem] = useState(null);

  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);

  const [isChangeStoreCart, setIsChangeStoreCart] = useState(false);
  const [selectedTag, setSelectedTag] = useState("all");

  const [isLoadingDistance, setIsLoadingDistance] = useState(false);

  const handleGetStore = useCallback(async () => {
    if (!user?.customerID) return;
    console.log("handleGetStore");
    try {
      setIsLoading(true);
      let url = `store/storeDetails?unquieid=${uniqueID}&customerID=${encodeValue(
        user?.customerID
      )}`;
      if (/^\d+$/.test(slug)) {
        url += `&storeID=${slug}`;
      } else {
        url += `&storeSlug=${slug}`;
      }

      if (user?.latitude && user?.longitude) {
        url += `&latitude=${user?.latitude}&longitude=${user?.longitude}`;
      }
      const res = await axiosInstance.get(url, {
        headers: {
          Accept: "application/json, text/plain, */*",
        },
      });
      if (res.data?.status === "ok") {
        const data = res.data?.result;
        setOutlet(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  }, [slug, user?.customerID, uniqueID]);

  const handleGetProducts = useCallback(async () => {
    console.log("handleGetProducts");
    try {
      setIsLoadingProduct(true);
      const store_id = encodeValue(state?.storeID ?? outlet?.storeID);
      let url = `catalogs/listproducts?unquieid=${uniqueID}&storeID=${store_id}`;
      const res = await axiosInstance.get(url, {
        headers: {
          Accept: "application/json, text/plain, */*",
        },
      });
      if (res.data?.status === "ok") {
        setProducts(res.data?.result || []);
      }
      setIsLoadingProduct(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoadingProduct(false);
    }
  }, [state?.storeID, outlet?.storeID, uniqueID]);

  const handleCart = async (data, isClose = true) => {
    console.log("handleCart", data);
    try {
      setIsLoading(true);
      let postData = {
        unquieid: uniqueID,
        availabilityID: deliveryId,
        availabilityName: "Delivery",
        shopID: encodeValue(outlet?.storeID),
        customerID: encodeValue(user?.customerID),
      };
      if (data?.productType === "Simple") {
        postData = {
          ...postData,
          ...data,
        };
      } else {
        postData = {
          ...postData,
          ...data,
          productTotalPrice: data?.totalPrice,
          productPrice: data?.price,
          comboset: JSON.stringify(data?.comboset),
        };
      }
      console.log("postData: ", postData);
      sendAnalyticsCustomEvent("add_to_cart", {
        customer_id: user?.customerID,
        value: postData?.productTotalPrice || postData?.price,
        merchants_id: state?.storeID || outlet?.storeID,
        items: postData,
      });

      const url = `cart/${
        data?.type === "add" ? "createCart" : "updateCartItem"
      }`;
      const res = await axiosInstance.post(url, qs.stringify(postData));
      if (res?.data?.status === "ok") {
        isClose && setCartItem(null);
        isClose && handleCloseBottomSheet();
        await handleGetCart();
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  };

  const handleRemoveCart = async () => {
    console.log("handleRemoveCart");
    try {
      setIsLoading(true);
      const postData = {
        unquieid: uniqueID,
        customerID: encodeValue(user?.customerID),
      };
      const res = await axiosInstance.post(
        `cart/removeCart`,
        qs.stringify(postData)
      );
      if (res?.data?.status === "ok") {
        setCart(null);
      }
      if (productSlug) {
        console.log("handleGetProductDetail", productSlug);
        await handleGetProductDetail(productSlug, true);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  };

  const handleGetCart = useCallback(async () => {
    console.log("handleGetCart");
    try {
      setIsLoading(true);
      const url = `cart/loadCartDetails?unquieid=${uniqueID}&customerID=${encodeValue(
        user?.customerID
      )}&availabilityID=${deliveryId}&storeID=${state?.storeID || outlet?.storeID}`;
      const res = await axiosInstance.get(url, {
        headers: {
          Accept: "application/json, text/plain, */*",
        },
      });
      if (res?.data?.status === "ok") {
        setCart(res?.data?.result || null);
      }
      if (res?.data?.message === "There are no items in your cart") {
        setCart(null);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  }, [user?.customerID, state?.storeID, outlet?.storeID]);

  const handleGetTags = useCallback(async () => {
    console.log("handleGetTags");
    try {
      setIsLoadingTags(true);
      const store_id = encodeValue(state?.storeID ?? outlet?.storeID);
      const url = `catalogs/listcategory?unquieid=${uniqueID}&storeID=${store_id}`;
      const res = await axiosInstance.get(url);

      if (res?.data?.status === "ok") {
        const allCategory = {
          categoryName: "All",
          categoryImage: "",
          catSlug: "all",
        };
        const updatedTags = [allCategory, ...(res?.data?.result || [])];
        setTags(updatedTags);
      }
      setIsLoadingTags(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoadingTags(false);
    }
  }, [state?.storeID, outlet?.storeID, uniqueID]);

  useEffect(() => {
    sendAnalyticsPageView("merchant_session");
  }, []);

  useEffect(() => {
    sendAnalyticsEvent(
      "Merchant Detail",
      "merchant_detail",
      state?.storeName || outlet?.storeName
    );
  }, []);

  useEffect(() => {
    sendAnalyticsEvent(
      "Merchant Detail",
      state?.storeName || outlet?.storeName,
      state?.storeName || outlet?.storeName
    );
  }, []);

  useEffect(() => {
    if (user?.customerID && products?.length) {
      sendAnalyticsCustomEvent("detail_merchant", {
        customer_id: user?.customerID,
        merchants_id: state?.storeID || outlet?.storeID,
        merchants_name: state?.storeName || outlet?.storeName,
        detail_merchants_items: products,
      });
    }
  }, [user?.customerID, products?.length]);

  useEffect(() => {
    handleGetStore();
  }, [user?.customerID]);

  useEffect(() => {
    if (!state?.storeID && !outlet?.storeID) return;
    handleGetCart();
  }, [user?.customerID, state?.storeID, outlet?.storeID]);

  useEffect(() => {
    if (state?.storeID && !outlet?.storeID) {
      console.log("useEffect if");
      handleGetProducts();
      handleGetTags();
    } else if (!state?.storeID && outlet?.storeID) {
      console.log("useEffect else if");
      handleGetProducts();
      handleGetTags();
    }
  }, [state?.storeID, outlet?.storeID]);

  useEffect(() => {
    if (!cart?.item?.length || isOpenBottomSheet) return;
    setIsShowTotalCart(true);
  }, [cart?.item?.length, isOpenBottomSheet]);

  const handleGetProductDetail = async (product, removeCart = false) => {
    if (!product?.product_slug && !product?.product_id) return;
    console.log("handleGetProductDetail");
    try {
      if (
        !removeCart &&
        cart?.store?.storeID &&
        cart?.store?.storeID !== outlet?.storeID
      ) {
        setProductSlug(product);
        return setIsChangeStoreCart(true);
      }
      setIsLoading(true);
      const url = `catalogs/productdetails?unquieid=${uniqueID}&productSlug=${product?.product_slug}&storeID=${encodeValue(state?.storeID ?? outlet?.storeID)}&productID=${product?.product_id}`;
      const res = await axiosInstance.get(url, {
        headers: {
          Accept: "application/json, text/plain, */*",
        },
      });
      if (res.data?.status === "ok") {
        const product = res.data?.result || null;
        setCartItem(product);
        if (product?.product_type === "1") {
          setIsShowSimpleProduct(true);
        }
        if (product?.product_type === "2") {
          setIsShowComboProduct(true);
        }
        setIsOpenBottomSheet(true);
        setIsShowTotalCart(false);
      }
      if (res?.data?.status === "error") {
        showToast("warning", "Product not found", 1000);
      }
      setProductSlug(null);
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  };

  const handleFavorite = useCallback(async () => {
    if (!user?.customerID) return;
    console.log("handleFavorite");
    try {
      setIsLoading(true);

      sendAnalyticsCustomEvent(
        Boolean(Number(outlet?.favorite || outlet?.favourite))
          ? "homepage_unfavorite"
          : "homepage_favorite",
        {
          customer_id: user?.customerID,
          ...outlet,
        }
      );

      const dataPost = {
        unquieid: uniqueID,
        shopID: encodeValue(outlet?.storeID),
        customerID: encodeValue(user?.customerID),
      };
      const url = `store/${
        Boolean(Number(outlet?.favorite || outlet?.favourite))
          ? "removeFavourite"
          : "addFavourite"
      }`;
      const res = await axiosInstance.post(url, qs.stringify(dataPost));
      if (res?.data?.status === "ok") {
        handleGetStore();
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  }, [outlet, user]);

  const handleCloseBottomSheet = () => {
    console.log("handleCloseBottomSheet");
    setIsOpenBottomSheet(false);
    setIsShowTotalCart(true);
    setIsShowSimpleProduct(false);
    setIsShowComboProduct(false);
    setIsChangeStoreCart(false);
  };

  const handleRemoveCartAndGetProduct = () => {
    console.log("handleRemoveCartAndGetProduct");
    handleCloseBottomSheet();
    handleRemoveCart();
  };

  const handleToSummary = () => {
    console.log("distance", outlet);
    console.log("distance", outlet?.distance);
    if (Number(outlet?.distance) <= 0.1) {
      setIsLoadingDistance(true);
      setIsShowTotalCart(false);
    } else {
      history.push("/summary");
    }
  };

  const handleCloseBottomSheetLocation = () => {
    setIsLoadingDistance(false);
    setIsShowTotalCart(true);
  };

  const filteredProduct = useMemo(() => {
    if (selectedTag === "all") return products;
    const filter = products?.filter(
      (product) => selectedTag === product?.pro_cate_slug
    );
    return filter;
  }, [selectedTag, products]);

  const groupedColumns = useMemo(() => {
    if (!filteredProduct?.length) return [];
    
    return Array.from({ length: Math.ceil(filteredProduct.length / 2) }, (_, i) => {
      const group = [i * 2, i * 2 + 1].filter(idx => idx < filteredProduct.length);  
      const names = group?.map((idx) => {
        return idx, filteredProduct?.[idx]?.product_name;
      })
      const longest_name = names?.reduce((longest, name) => 
        name?.length > longest?.length ? name : longest, ""
      );
      return { group, names, longest_name };
    });
  }, [filteredProduct]);

  return (
    <>
      {isLoading && <Loader overlay />}
      <div className="h-screen w-full flex justify-center items-center">
        <div className="h-screen w-full max-w-lg">
          <div className={`absolute top-0 mt-5 w-full max-w-lg z-5`}>
            <div className="flex flex-row justify-between mx-4">
              <div
                className="flex justify-center items-center cursor-pointer"
                onClick={() => history.push("/")}
                id="merchant_back"
              >
                <ArrowLeftIcon className="h-8 w-8 text-gray-900 bg-white rounded-full p-1.5" />
              </div>
              <div className="flex gap-2">
                <div
                  className="hidden justify-center items-center cursor-pointer"
                  onClick={() => history.push("/")}
                >
                  <MagnifyingGlassIcon className="h-8 w-8 text-gray-900 bg-white rounded-full p-1.5" />
                </div>
                <div
                  className="flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    sendAnalyticsEvent(
                      Boolean(Number(outlet?.favorite || outlet?.favourite))
                        ? "Click Unfavorite"
                        : "Click Favorite",
                      Boolean(Number(outlet?.favorite || outlet?.favourite))
                        ? "merchant_unfavorite_outlet"
                        : "merchant_favorite_outlet",
                      state?.storeName || outlet?.storeName
                    );
                    handleFavorite();
                  }}
                  id={
                    Boolean(Number(outlet?.favorite || outlet?.favourite))
                      ? "merchant_unfavorite_outlet"
                      : "merchant_favorite_outlet"
                  }
                >
                  <HeartIcon
                    className={`h-8 w-8 ${
                      Boolean(Number(outlet?.favorite || outlet?.favourite))
                        ? "fill-red-500 text-red-500"
                        : "text-gray-900"
                    } bg-white rounded-full p-1.5`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="h-full max-h-52 relative">
            <div className="aspect-[2/1] w-full h-full">
              <ImageLoader
                alt={state?.storeSlug || outlet?.storeSlug}
                src={state?.bannerImage || outlet?.bannerImage}
                className="h-full w-full object-cover"
              />
            </div>
            <div className="p-4 absolute w-full max-w-lg -bottom-20">
              <div className="p-4 rounded-lg shadow-xl bg-white flex">
                <ImageLoader
                  alt={slug || outlet?.storeSlug}
                  src={state?.storeImage || outlet?.storeImage}
                  className="h-12 w-12 rounded-lg resize-none bg-gray-400"
                />
                <div className="pl-2">
                  <div className="text-gray-900 text-sm font-semibold">
                    {state?.storeName || outlet?.storeName}
                  </div>
                  <div className="flex gap-1 items-center pt-1">
                    <StarIcon className="h-4 w-4 text-star" />
                    <div className="text-black text-xs">
                      {state?.rating || outlet?.rating || outlet?.Rating || 0}
                    </div>
                    <div className="text-gray-900 text-xs">
                      ({state?.totalRating || outlet?.totalRating || 0})
                    </div>
                  </div>
                  <div className="flex flex-row gap-1 pt-1">
                    <BikeIcon />
                    <div className="flex flex-row">
                      <div className="text-gray-900 text-xs">
                        {Math.floor(Number(outlet?.distance || 0) * 10) / 10} km
                      </div>
                      <div className="hidden text-xs">
                        <div className="px-1">-</div>
                        <div className="flex flex-row">
                          Delivery <p className="px-1 line-through">Rp16.000</p>{" "}
                          Rp6.000
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      !outlet?.offerInfo && "hidden"
                    } gap-1 pt-1 flex items-center`}
                  >
                    <TagIcon />
                    <div className="font-semibold text-xs text-gray-900">
                      {outlet?.offerInfo}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-4 mt-24 hidden">
            <div className="text-base text-gray-800 font-semibold">
              Favorite’s Items You Maybe Like
            </div>
            <div className="mt-4 flex overflow-scroll scrollbar-hide">
              {filteredProduct?.map((product, index) => {
                return (
                  <ProductDetail
                    key={`favorite’s_${product?.product_id}_${index}`}
                    id={product?.product_id}
                    slug={product?.product_slug}
                    image={product?.product_thumbnail}
                    rating={outlet?.rating || outlet?.Rating}
                    totalRating={outlet?.totalRating}
                    name={product?.product_name}
                    price={Number(product?.product_price)}
                    strikeOutPrice={Number(product?.product_cost)}
                    size="small"
                    productType={product?.product_type}
                    handleGetProductDetail={() => handleGetProductDetail(product)}
                    cart={cart}
                    handleCart={handleCart}
                  />
                );
              })}
            </div>
          </div>
          <div className={`mx-4 mt-24 ${cart && "pb-28"}`}>
            <div className="text-base text-gray-800 font-semibold">
              Feature Items
            </div>
            {isLoadingTags ? (
              <div className="py-1.5 px-3 w-full flex justify-center">
                <LoaderIcon />
              </div>
            ) : (
              <div className="w-full my-2">
                <div className="grid grid-flow-col auto-cols-max overflow-x-auto snap-x snap-mandatory scroll-smooth scroll-px-4 gap-2 scrollbar-hide">
                  {tags?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`snap-start cursor-pointer rounded-full bg-gray-100 px-3 py-1.5 text-gray-500 ${
                          selectedTag === item?.catSlug &&
                          "bg-rose-violet bg-opacity-15 text-rose-violet"
                        }`}
                        onClick={() => setSelectedTag(item?.catSlug)}
                      >
                        <span className="text-center text-nowrap text-xs">
                          {item?.categoryName}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {isLoadingProduct ? (
              <div className="w-full h-[calc(100vh/2)] flex justify-center items-center bg-red-500}">
                <LoaderIcon />
              </div>
            ) : (
              <div className={`mt-4 grid grid-cols-2 gap-4`}>
                {filteredProduct?.map((product, index) => {
                  return (
                    <ProductDetail
                      key={`feature_${product?.product_id}_${index}`}
                      id={product?.product_id}
                      slug={product?.product_slug}
                      image={product?.product_thumbnail}
                      rating={outlet?.rating || outlet?.Rating}
                      totalRating={outlet?.totalRating}
                      name={product?.product_name}
                      price={Number(product?.product_price)}
                      strikeOutPrice={Number(product?.product_cost)}
                      productType={product?.product_type}
                      handleGetProductDetail={() => handleGetProductDetail(product)}
                      cart={cart}
                      handleCart={handleCart}
                      groupedColumns={groupedColumns}
                      idx={index}
                    />
                  );
                })}
              </div>
            )}
          </div>
          {isShowTotalCart && (
            <Footer position="fixed" id="cart">
              <div
                className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out ${
                  cart ? "translate-y-0 relative" : "translate-y-full hidden"
                }`}
              >
                <div className="items-center hidden">
                  <DiscountIcon />
                  <div className="pl-2 text-gray-900 text-xs">
                    Yay, you’re saving 10k. Add more and save up to 50k!
                  </div>
                </div>
                <button
                  className="mt-2 w-full bg-rose-violet text-white flex justify-between font-normal text-sm p-3 border-0items-center border-0"
                  onClick={() => {
                    sendAnalyticsEvent(
                      "Click Button Summary",
                      "merchant_item_in_cart",
                      state?.storeName || outlet?.storeName
                    );
                    handleToSummary();
                  }}
                  id="merchant_item_in_cart"
                >
                  <div>{cart?.totalItem} item in cart</div>
                  <div>{formatRupiah(parseInt(cart?.subTotal || 0))}</div>
                </button>
              </div>
            </Footer>
          )}
          {isChangeStoreCart && (
            <Footer
              position="fixed"
              id="isChangeStoreCart"
              onTouchOutside={() => {
                setIsChangeStoreCart(false);
                setProductSlug(null);
              }}
            >
              <div
                className={`bg-white p-4 rounded-xl shadow-custom transition-transform duration-1000 ease-in-out ${
                  isChangeStoreCart
                    ? "translate-y-0 relative"
                    : "translate-y-full hidden"
                }`}
              >
                <div className="flex justify-center">
                  <div className="bg-gray-300 h-1.5 w-10 rounded-full" />
                </div>
                <div className="items-center flex justify-center mt-8">
                  <WishtlingIcon />
                </div>
                <div className="my-5 text-center">
                  <div className="text-gray-900 text-sm font-semibold">
                    Want to buy from this restaurant?
                  </div>
                  <div className="text-gray-900 text-xs pt-2">
                    We'll need to clear the items in your current cart.
                  </div>
                </div>
                <div className="flex justify-between mt-2 gap-4">
                  <button
                    className="w-full bg-white text-rose-violet border-rose-violet font-semibold text-sm p-3 items-center border"
                    onClick={() => {
                      setIsChangeStoreCart(false);
                      setProductSlug(null);
                    }}
                    id="merchant_change_cart_cancel"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleRemoveCartAndGetProduct}
                    className="w-full bg-rose-violet text-white font-semibold text-sm p-3 items-center border-0"
                    id="merchant_change_cart_confirm"
                  >
                    Yes, Sure
                  </button>
                </div>
              </div>
            </Footer>
          )}
          {isOpenBottomSheet && (
            <BottomSheet
              open={isOpenBottomSheet}
              onDismiss={handleCloseBottomSheet}
              snapPoints={({ minHeight, maxHeight }) => {
                // 544 932
                if (isShowComboProduct && cartItem?.comboset?.length && !cart) {
                  return [755];
                }
                return [minHeight];
              }}
            >
              {isShowSimpleProduct && (
                <ProductSimple
                  product={cartItem}
                  cart={cart}
                  handleCart={handleCart}
                  setCartItem={setCartItem}
                />
              )}
              {isShowComboProduct && (
                <ProductCombo
                  product={cartItem}
                  cart={cart}
                  handleCart={handleCart}
                  setCartItem={setCartItem}
                />
              )}
            </BottomSheet>
          )}

          {isLoadingDistance && (
            <NoOutlet
              isOpen={isLoadingDistance}
              onClose={() => handleCloseBottomSheetLocation()}
              sameLocation={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Outlet;
